







import { Component, Mixins, Vue } from 'vue-property-decorator'
import Title from '@/components/modules/drillsv3/atoms/Title.vue'
import ResultGuide from '@/components/modules/drillsv3/atoms/ResultGuide.vue'
import Correct from '@/components/modules/drillsv3/organisms/Correct.vue'
import { PAGE_TYPE } from '@/store/modules/DrillsV3'
import DrillsConfig from '@/mixins/drillsv3/Config'
import DrillTransition from '@/mixins/drillsv3/DrillTransition'
import DrillWebApi from '@/mixins/drillsv3/WebApi'
import DrillHistoryApi from '@/mixins/drillsv3/HistoryApi'
import ErrorMessage from '@/components/atoms/v3/ErrorMessage.vue'

@Component({
  components: {
    Title,
    ResultGuide,
    Correct,
    ErrorMessage,
  },
})
export default class DrillResult extends Mixins(DrillWebApi, DrillHistoryApi, DrillTransition) {
  // 読み込み完了フラグ
  private loadComplete = false

  private error = false

  private drillModules = DrillsConfig.drillModules

  private get page(): number {
    return Number(this.$route.query.page)
  }

  private version = this.$route.params.type

  protected async mounted(): Promise<any> {
    try {
      Vue.prototype.$logger.info('-- DrillResult V3 mounted')

      // 読み込み完了フラグを未完了に変更
      this.$store.commit('drillsV3/setProcessed', false)

      // ページタイプ設定
      this.$store.commit('drillsV3/setPageType', PAGE_TYPE.RESULT_UNIT)

      // 正誤入力情報を取得
      const page: number = +this.$route.query?.page || 1
      const version = ['v2', 'v3'].includes(this.version) ? this.version : 'v3'
      const { resultDrillPages } = await this.getResultDrill(this.resultDrillId, version)
      const resultProblem = resultDrillPages.find((item) => item.pageNum == page)
      this.$store.commit('drillsV3/setCurrentPage', Number(resultProblem?.pageNum))
      Vue.prototype.$loading.complete()

      // 表示用の情報を取得し、ストアに保持
      const sProblemIds = [
        resultProblem?.problems.map((problem) => {
          return problem.sProblemId
        }) || [],
      ]
      const problems = await this.getProblems(sProblemIds, version)
      await this.$store.dispatch('drillsV3/setProblems', problems)

      // 正誤情報をストアに保持
      await this.$store.dispatch('drillsV3/setProblemCorrects', [resultProblem?.problems])
      await this.$store.dispatch('drillsV3/setResultProblem', resultProblem)

      // 動画視聴状況を登録
      await this.$store.dispatch('drillsV3/setMoviePlayed', [resultProblem?.problems])

      // 読み込み完了フラグを設定
      this.$store.commit('drillsV3/setProcessed', true)
      this.loadComplete = true

      Vue.prototype.$loading.complete()
    } catch {
      this.error = true
    }
  }
}
